import React from 'react';
import { IndexPage as StyledIndexPage, Tagline, Author } from './styled';

const tagline =
  'I let my heart bleed on my pen so that I can share with you the words that come from my soul.';
const author = '— Andrew Messner';

function IndexPage() {
  return (
    <StyledIndexPage>
      <Tagline>
        <span>{tagline}</span>
      </Tagline>

      <Author>{author}</Author>
    </StyledIndexPage>
  );
}

export default IndexPage;
