import { Page } from '../Page/styled';
import styled from 'styled-components';
import { rem } from 'polished';

export const IndexPage = styled(Page)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Tagline = styled.h1`
  position: relative;
  font-style: italic;
  font-family: ${props => props.theme.font.family.serif};
  font-weight: normal;
  margin-bottom: 0.25rem;
  font-size: ${rem(28)};

  ${props => props.theme.mediaUp.lg`
    font-size: ${rem(40)};
  `}

  &::before {
    content: '“';
    position: absolute;
    left: -35px;
    top: -35px;
    font-size: ${rem(80)};
    font-family: Georgia, Times, serif;
    color: ${props => props.theme.color.primary};
    z-index: -1;

    ${props => props.theme.mediaUp.lg`
      left: -60px;
      top: -60px;
      font-size: ${rem(120)};
    `}
  }
`;

export const Author = styled.p`
  font-weight: bold;
  font-size: ${rem(14)};
  margin-bottom: 0;
`;
